import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>Travel Berlin</h1>
            <h4>May - July 2019</h4>
            <p>
                In this project I lead the front end development for a website that a team of undergraduate students and I created called Travel Berlin. The purpose of the website was to help tourists new to Berlin to learn about the major sites, as well as provide reviews about the sites. The website was built using a React front end and a Scala Play backend. You can view the source code to the project <a rel="noopener noreferrer"  href="https://github.com/LucasKiefer/TravelBerlin" target="_blank">here</a>.
            </p>
        </ProjectPage>
    </div>)
}

export default IndexPage
